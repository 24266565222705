import { Link } from "react-router-dom"
import { List } from "antd"
import "./index.scss"

const data = [
  {
    title: "Terms & conditions",
    href: "#",
  },
  {
    title: "Privacy policy",
    href: "#",
  },
];

export const LegalDocs = ({...props}) => {
  return (
    <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <Link to={item.href} className={"legal-docs-link"}>
            {item.title}
          </Link>
        </List.Item>
      )}
      className={"legal-docs"}
      {...props}
    />
  )
}