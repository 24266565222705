import { FC, useState } from "react"
import { Menu, MenuProps } from "antd"
import { Link } from "react-router-dom"
import "./index.scss"

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    label: <Link to={"/"} className={"menu-link"}>Browse properties</Link>,
    key: "Browse properties",
  },
  {
    label: <Link to={"/"} className={"menu-link"}>About</Link>,
    key: "About",
  },
  {
    label: <Link to={"/"} className={"menu-link"}>Portfolio</Link>,
    key: "Portfolio",
  },
  {
    label: <Link to={"/"} className={"menu-link"}>Transactions</Link>,
    key: "Transactions",
  },
];

export const HeaderNavMenu: FC = ({...props}) => {
  const [current, setCurrent] = useState("Browse properties");

  const onMenuItemClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  return (
    <Menu
      className={"nav-menu"}
      mode={"horizontal"}
      items={items}
      selectedKeys={[current]}
      onClick={onMenuItemClick}
      {...props}
    />
  )
}
