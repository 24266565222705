import { Avatar, Button, Flex } from "antd"
import { Link, useLocation } from "react-router-dom"
import { FC, useState } from "react"
import { useBreakpoints } from "../../../../hooks/useBreakpoints"
import { HeaderNavMenu } from "./ui/HeaderNavMenu"
import { MobileMenu } from "./ui/MobileMenu"
import { SvgIcon } from "../../../../shared/icons"
import { Logo } from "../../../ui/Logo"
import ProfileImg from "src/shared/images/profileExample.png"
import "./index.scss"

export const Header: FC = () => {
  const {isDesktop} = useBreakpoints()
  const { pathname } = useLocation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleOpenMenu = () => {
    setIsMenuOpened(true)
  }

  const handleCloseMenu = () => {
    setIsMenuOpened(false)
  }

  const handleSignUpClick = () => {
    window.location.href = "/sign-up"
  }

  return (
    <>
      <Flex className={"header-content"} justify={"space-between"} align={"center"}>
        <Link to={"/"} style={{ flexShrink: 0 }}>
          <Logo color={"blue"} style={{ cursor: "pointer" }} />
        </Link>
        {isDesktop ? (
          <>
            <HeaderNavMenu />
            {pathname.endsWith("/landing") ? (
              <Button
                type={"primary"}
                className={"sign-btn"}
                onClick={handleSignUpClick}
              >
                Sign Up
              </Button>
            ) : (
              <Flex align={"center"} gap={24}>
                <SvgIcon type={"notifications"} className={"notifications-icon"} />
                <Avatar size={48} src={ProfileImg} className={"avatar"} />
              </Flex>
            )}
          </>
        ) : (
          <Button
            className={"burger-menu-btn"}
            icon={<SvgIcon type={"burgerMenu"} width={24} height={24} />}
            onClick={handleOpenMenu}
          />
        )}
      </Flex>
      {isMenuOpened && <MobileMenu onClose={handleCloseMenu} />}
    </>
  )
}
