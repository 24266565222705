import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "'Satoshi Variable', sans-serif",
    colorText: "#393939",
  },
  components: {
    Layout: {
      headerPadding: "24px 80px",
      headerBg: "#FFF",
      bodyBg: "#FFF",
      footerPadding: "70px 80px",
      footerBg: "#000",
    },
    Menu: {
      fontSize: 16,
      fontWeightStrong: 500,
      itemPaddingInline: 16,
      itemColor: "#555555",
      lineWidthBold: 1.5,
      horizontalItemSelectedColor: "#2E3BCB",
    },
    Button: {
      paddingBlock: 11,
      paddingInline: 16,
      borderRadius: 100,
      fontSize: 16,
      fontWeight: 700,
      defaultBg: "#FFF",
      defaultHoverBg: "inherit",
      defaultActiveBg: "inherit",
      defaultColor: "#393939",
      defaultHoverColor: "inherit",
      defaultActiveColor: "inherit",
      defaultBorderColor: "#2E3BCB",
      defaultHoverBorderColor: "#2E3BCB",
      defaultShadow: "unset",
      colorPrimary: "#2E3BCB",
      colorPrimaryBorder: "#2E3BCB",
      primaryColor: "#FBFBFB",
      primaryShadow: "unset",
      defaultGhostColor: "#2E3BCB",
      defaultGhostBorderColor: "transparent",
    },
    Input: {
      paddingBlock: 11,
      paddingInline: 16,
      inputFontSize: 16,
      colorText: "#1C1C1C",
      borderRadius: 24,
      colorBorder: "#E3E3E3",
      hoverBorderColor: "#2E3BCB",
      activeBorderColor: "#2E3BCB",
      activeShadow: "unset",
      errorActiveShadow: "transparent",
    },
    Select: {
      fontSize: 16,
      borderRadius: 24,
    },
  },
}

export default theme
