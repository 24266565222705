import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header } from "."
import { useBreakpoints } from "../../hooks/useBreakpoints"

export const Layout: FC = () => {
  const {isDesktop} = useBreakpoints()

  return (
    <BaseLayout style={{ display: "flex", flexDirection: "column", alignItems: "stretch", minHeight: "100vh"}}>
      <BaseLayout.Header style={{ padding: isDesktop ? "24px 80px" : "16px 24px" }}>
        <Header />
      </BaseLayout.Header>
      <BaseLayout style={{ padding: isDesktop ? "0 80px" : "0 24px" }}>
        <Suspense fallback="loading...">
          <Outlet />
        </Suspense>
      </BaseLayout>
      <BaseLayout.Footer style={{ padding: isDesktop ? "70px 80px" : "40px 24px" }}>
        <Footer />
      </BaseLayout.Footer>
    </BaseLayout>
  )
}
