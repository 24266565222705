import { Button, Col, Flex, Row, Typography } from "antd"
import { Link } from "react-router-dom"
import { FC } from "react"
import { useBreakpoints } from "../../../../hooks/useBreakpoints"
import { FooterNavMenu } from "./ui/FooterNavMenu"
import { SocialMedia } from "./ui/SocialMedia"
import { LegalDocs } from "./ui/LegalDocs"
import { SvgIcon } from "../../../../shared/icons"
import { Logo } from "../../../ui/Logo"
import "./index.scss"

export const Footer: FC = () => {
  const {isDesktop} = useBreakpoints()

  const handleBackToTopClick = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <Col className={"footer-content"}>
      <Row gutter={isDesktop ? [8, 8] : [0, 0]} style={{ width: "100%" }}>
        <Col span={isDesktop ? 12 : 24}>
          <FooterNavMenu />
        </Col>
        <Col span={isDesktop ? 12 : 24}>
          <Flex vertical={!isDesktop} justify={"space-between"} style={{ marginTop: isDesktop ? 0 : 40 }}>
            <Col>
              <Col>
                <Typography.Paragraph className={"lets-talk-title"}>Talk with</Typography.Paragraph>
                <Typography.Paragraph className={"lets-talk-title"}>an expert ar RealTech Dao</Typography.Paragraph>
              </Col>
              <Button className={"lets-talk-btn"}>
                <SvgIcon type={"arrow"} width={24} height={24} className={"lets-talk-icon"} />
                Let’s talk
              </Button>
            </Col>
            <SocialMedia style={{ marginTop: isDesktop ? 0 : 40}} />
          </Flex>
        </Col>
      </Row>
      <Row gutter={isDesktop ? [8, 8] : [0, 0]} style={{ width: "100%", marginTop: isDesktop ? 88 : 56 }}>
        <Col span={isDesktop ? 12 : 24} style={{ order: isDesktop ? 1 : 2, marginTop: isDesktop ? 0 : 40 }}>
          <Link to={"/"}>
            <Logo
              size={"large"}
              color={"white"}
              iconWidth={32}
              iconHeight={32}
              className={"footer-logo"}
            />
          </Link>
          <Typography.Paragraph className={"copyright"}>© 2024 Real&tech interiors. All rights reserved.</Typography.Paragraph>
        </Col>
        <Col span={isDesktop ? 12 : 24} style={{ order: isDesktop ? 2 : 1 }}>
          <Flex justify={"space-between"} align={"flex-end"} style={{ height: "100%" }}>
            <Flex vertical={true} justify={"flex-end"}>
              <LegalDocs />
            </Flex>
            <Button className={"back-to-top-btn"} onClick={handleBackToTopClick}>
              <Flex className={"back-to-top-icon-wrapper"}>
                <SvgIcon type={"arrow"} width={24} height={24} className={"back-to-top-icon"} />
              </Flex>
              Back to top
            </Button>
          </Flex>
        </Col>
      </Row>
    </Col>
  )
}
