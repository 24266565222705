import { Button, Col, Flex, List } from "antd"
import { Link } from "react-router-dom"
import { FC } from "react"
import { Logo } from "../../../../../ui/Logo"
import { SvgIcon } from "../../../../../../shared/icons"
import "./index.scss"

interface MobileMenuProps {
  onClose: () => void;
}

const data = [
  {
    title: "Browse properties",
    href: "/",
  },
  {
    title: "About",
    href: "#",
  },
  {
    title: "Portfolio",
    href: "#",
  },
  {
    title: "Transactions",
    href: "#",
  },
];

export const MobileMenu: FC<MobileMenuProps> = ({ onClose, ...props }) => {
  const handleSignUpClick = () => {
    window.location.href = "/sign-up"
  }

  return (
    <Col className={"mobile-menu"} {...props}>
      <Flex className={"mobile-header"} justify={"space-between"} align={"center"}>
        <Link to={"/"}>
          <Logo color={"blue"} style={{ cursor: "pointer" }} />
        </Link>
        <Button
          className={"mobile-menu-close-btn"}
          icon={<SvgIcon type={"close"} width={24} height={24} />}
          onClick={onClose}
        />
      </Flex>
      <Flex className={"mobile-menu-main"} vertical={true} align={"center"}>
        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Link to={item.href} className={"mobile-menu-link"}>
                {item.title}
              </Link>
            </List.Item>
          )}
          className={"mobile-menu-nav"}
        />
        <Button
          type={"primary"}
          className={"sign-up-btn"}
          onClick={handleSignUpClick}
        >
          Sign Up
        </Button>
      </Flex>
    </Col>
  )
}