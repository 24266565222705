import { Link } from "react-router-dom"
import { List } from "antd"
import { SvgIcon } from "../../../../../../shared/icons"
import "./index.scss"

const data = [
  {
    icon: "facebook2",
    href: "https://www.facebook.com/",
  },
  {
    icon: "linkedIn",
    href: "https://www.linkedin.com/",
  },
  {
    icon: "instagram",
    href: "https://www.instagram.com/",
  },
  {
    icon: "x",
    href: "https://twitter.com/",
  },
];

export const SocialMedia = ({...props}) => {
  return (
    <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <Link to={item.href} target={"_blank"} className={"social-media-link"}>
            <SvgIcon type={item.icon} width={24} height={24} className={"social-media-icon"} />
          </Link>
        </List.Item>
      )}
      className={"social-media"}
      {...props}
    />
  )
}