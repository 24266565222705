import { FC, SVGProps } from "react"

import { ReactComponent as Arrow } from "src/shared/icons/arrow.svg"
import { ReactComponent as ArrowSmall } from "src/shared/icons/arrowSmall.svg"
import { ReactComponent as Bath } from "src/shared/icons/bath.svg"
import { ReactComponent as Bed } from "src/shared/icons/bed.svg"
import { ReactComponent as BurgerMenu } from "src/shared/icons/burgerMenu.svg"
import { ReactComponent as CircleWithArrows } from "src/shared/icons/circleWithArrows.svg"
import { ReactComponent as Close } from "src/shared/icons/close.svg"
import { ReactComponent as Copy } from "src/shared/icons/copy.svg"
import { ReactComponent as EyeOpened } from "src/shared/icons/eyeOpened.svg"
import { ReactComponent as EyeClosed } from "src/shared/icons/eyeClosed.svg"
import { ReactComponent as House } from "src/shared/icons/house.svg"
import { ReactComponent as Info } from "src/shared/icons/info.svg"
import { ReactComponent as Facebook } from "src/shared/icons/facebook.svg"
import { ReactComponent as Facebook2 } from "src/shared/icons/facebook2.svg"
import { ReactComponent as Google } from "src/shared/icons/google.svg"
import { ReactComponent as Instagram } from "src/shared/icons/instagram.svg"
import { ReactComponent as LinkedIn } from "src/shared/icons/linkedIn.svg"
import { ReactComponent as LogoWhite } from "src/shared/icons/logoWhite.svg"
import { ReactComponent as LogoBlue } from "src/shared/icons/logoBlue.svg"
import { ReactComponent as LogoGreen } from "src/shared/icons/logoGreen.svg"
import { ReactComponent as Notifications } from "src/shared/icons/notifications.svg"
import { ReactComponent as Sort } from "src/shared/icons/sort.svg"
import { ReactComponent as GrowUp } from "src/shared/icons/growUp.svg"
import { ReactComponent as GrowDown } from "src/shared/icons/growDown.svg"
import { ReactComponent as X } from "src/shared/icons/x.svg"

const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  arrow: Arrow,
  arrowSmall: ArrowSmall,
  bath: Bath,
  bed: Bed,
  burgerMenu: BurgerMenu,
  close: Close,
  circleWithArrows: CircleWithArrows,
  copy: Copy,
  eyeOpened: EyeOpened,
  eyeClosed: EyeClosed,
  facebook: Facebook,
  facebook2: Facebook2,
  google: Google,
  growUp: GrowUp,
  growDown: GrowDown,
  house: House,
  info: Info,
  instagram: Instagram,
  linkedIn: LinkedIn,
  logoWhite: LogoWhite,
  logoBlue: LogoBlue,
  logoGreen: LogoGreen,
  notifications: Notifications,
  sort: Sort,
  x: X,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: string }

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}
