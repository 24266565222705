import { CSSProperties, FC } from "react"
import { Flex, Typography } from "antd"
import { SvgIcon } from "../../../shared/icons"
import "./index.scss"

interface LogoProps {
  color?: "white" | "blue" | "green"
  size?: "large" | "small"
  iconWidth?: number | string
  iconHeight?: number | string
  className?: string
  style?: CSSProperties
  onClick?: () => void
}

export const Logo: FC<LogoProps> = ({ color, size, iconWidth, iconHeight, className, style, onClick, ...props }) => {
  return (
    <Flex
      className={className ? `logo ${className}` : "logo"}
      style={style}
      onClick={onClick}
      {...props}
    >
      <SvgIcon
        className={"Logo-icon"}
        type={color === "white" ? "logoWhite" : color === "blue" ? "logoBlue" : "logoGreen"}
        width={iconWidth || 24} height={iconHeight || 24}
      />
      <Typography.Paragraph
        className={"logo-text"}
        style={{ color: color === "white" ? "#FFF" : color === "blue" ? "#2E3BCB" : "#1A3D3C" }}
      >
        RealTech {size === "large" && "Dao"}
      </Typography.Paragraph>
    </Flex>
  )
}