import { FC, lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"

import Layout, { Loader } from "../components/layout"

const Properties = lazy<FC>(() => import("./properties"))
const SignIn = lazy<FC>(() => import("./sign-in"))
const SignUp = lazy<FC>(() => import("./sign-up"))

const routes: RouteObject[] = [
  {
    Component: Layout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Properties,
        path: "/properties",
        children: [
          {
            Component: Properties,
            path: "browse",
            index: true,
          },
          {
            Component: Properties,
            path: ":propertyId",
          },
        ],
      },
      {
        element: <Navigate to={"/properties/browse"} replace />,
        path: "*",
      },
    ],
  },
  {
    Component: SignIn,
    path: "/sign-in",
  },
  {
    Component: SignUp,
    path: "/sign-up",
  },
]

export default routes
